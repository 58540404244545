import React from 'react';
import Clock from './Clock';

const App = () => {
  return (
    <main>
      {/* <a href='https://pngtree.com/so/famous-buildings'>
        famous buildings png from pngtree.com
      </a> */}
      <Clock />
    </main>
  );
};

export default App;
